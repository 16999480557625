// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

//
// Modals
// Material Design element Dialogs
// --------------------------------------------------
.modal-content {
  @include shadow-z-5();
  border-radius: $border-radius-large;
  border: none;
  // Modal header
  // Top section of the modal w/ title and dismiss
  .modal-header {
    border-bottom: none;
    padding-top:    24px;
    padding-right:  24px;
    padding-bottom: 0;
    padding-left:   24px;
  }
  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modal-body {
    padding-top:    24px;
    padding-right:  24px;
    padding-bottom: 16px;
    padding-left:   24px;
  }
  // Footer (for actions)
  .modal-footer {
    border-top: none;

    &.text-center{
        text-align: center;
    }

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;
      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }
    button+button {
      margin-bottom: 16px;
    }
  }
  .modal-body + .modal-footer {
    padding-top: 0;
  }
}
.modal-backdrop {
  background: rgba(0,0,0,0.3);
}

.modal{
    .modal-dialog{
        margin-top: 100px;
    }
    .modal-header .close{
        color: $gray-light;

        &:hover,
        &:focus{
            opacity: 1;
        }

        i{
            font-size: 16px;
        }
    }
}

.modal-notice {
    .instruction{
         margin-bottom: 25px;
    }
    .picture{
        max-width: 150px;
    }

    .modal-content{
        .btn-raised{
            margin-bottom: 15px;
        }
    }
}

.modal-small{
    width: 300px;
    margin: 0 auto;

    .modal-body{
        margin-top: 20px;
    }
}
