// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.nav-tabs {
    background: $brand-primary;
    border: 0;
    border-radius: $border-radius-base;
    padding: 0 $padding-base;

    > li {
        > a {
            color: #FFFFFF;
            border: 0;
            margin: 0;

            border-radius: $border-radius-base;

            line-height: $mdb-btn-font-size-base * 2;
            text-transform: uppercase;
            font-size: $mdb-btn-font-size-base;

            &:hover {
                background-color: transparent;
                border: 0;
            }
        }
        & > a,
        & > a:hover,
        & > a:focus {
            background-color: transparent;
            border: 0 !important;
            color: #FFFFFF !important;
            font-weight: $font-weight-bold;
        }
        &.disabled > a,
        &.disabled > a:hover {
            color: rgba(255,255,255,0.5);
        }

        .material-icons{
            margin: -1px 5px 0 0;
        }
    }

    >li.active{
        & > a,
        & > a:hover,
        & > a:focus {
            background-color: rgba(255,255,255, .2);
            transition: background-color .1s .2s;
        }
    }

}
