// Borrowed from Bootstrap4 spacers.
// https://github.com/twbs/bootstrap/blob/v4-dev/scss/utilities/_spacing.scss
$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)
) !default;

$infix: ''; // We do not have bootstrap breakpoint abbreviations.

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {

    .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
    .#{$abbrev}t#{$infix}-#{$size},
    .#{$abbrev}y#{$infix}-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r#{$infix}-#{$size},
    .#{$abbrev}x#{$infix}-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b#{$infix}-#{$size},
    .#{$abbrev}y#{$infix}-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l#{$infix}-#{$size},
    .#{$abbrev}x#{$infix}-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

// Some special margin utils
.m#{$infix}-auto { margin: auto !important; }
.mt#{$infix}-auto,
.my#{$infix}-auto {
  margin-top: auto !important;
}
.mr#{$infix}-auto,
.mx#{$infix}-auto {
  margin-right: auto !important;
}
.mb#{$infix}-auto,
.my#{$infix}-auto {
  margin-bottom: auto !important;
}
.ml#{$infix}-auto,
.mx#{$infix}-auto {
  margin-left: auto !important;
}